const game = {
  fetchItem: (id) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/config/getGameConfig?gameID=${id}`,
      {
        credentials: "same-origin", // include, *same-origin, omit
      }
    );
  },
  fetchAll: () => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/config/getHomeConfig`,
      {
        credentials: "same-origin", // include, *same-origin, omit
      }
    );
  },
  fetchLoginWithEmail: (email, password, mapID, appID, countryID) => {
    console.log("login email");
    //return fetch(
    //  `${process.env.REACT_APP_LOGIN_ENDPOINT}/login/email?mapID=${mapID}&appID=${appID}&lang=${countryID}&e=${email}&p=${password}&ver=v2`,
    //  {
    //    credentials: "same-origin", // include, *same-origin, omit
    //  }
    //);
    let forms = {
      mapID,
      appID,
      lang: countryID,
      e: email,
      p: password,
      ver: "v2",
    };

    let formBody = [];
    for (let property in forms) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(forms[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${process.env.REACT_APP_LOGIN_ENDPOINT}/login/email`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    });
  },
  fetchLoginWithSocialNetwork: (loginTypeName, gameID, mapID, countryID) => {
    return fetch(
      `${process.env.REACT_APP_LOGIN_ENDPOINT}/oauth/${loginTypeName}?gameID=${gameID}&mapID=${mapID}&lang=${countryID}&ver=v2`,
      {
        credentials: "same-origin", // include, *same-origin, omit
      }
    );
  },
  fetchUser: (gameID, userID) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/getUser?gameID=${gameID}&userID=${userID}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  fetchUserLoginInfo: (gameID, userID) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/getLoginInfo?gameID=${gameID}&userID=${userID}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  fetchUserAdvantage: (gameID, userID, type) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/getUser?gameID=${gameID}&userID=${userID}&getType=${type}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  fetchUpdateUser: (gameID, userID, params) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/updateUserBasicInfo?gameID=${gameID}&userID=${userID}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header
      }
    );
  },
  fetchRegister: (gameID, userID, params) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/register?gameID=${gameID}&userID=${userID}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header
      }
    );
  },
  fetchGuardianRegister: (gameID, userID, params) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/registerByGuardian?gameID=${gameID}&userID=${userID}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header
      }
    );
  },
  fetchLogout: (logoutArr) => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user/logout`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      "Content-Type": "application/json",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(logoutArr), // body data type must match "Content-Type" header
    });
  },
  fetchVerifyPhone: (gameID, userID, otp, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyPhone?gameID=${gameID}&userID=${userID}&otp=${otp}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchVerifyEmail: (gameID, userID, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyEmail?gameID=${gameID}&userID=${userID}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchVerifyCaptcha: (gameID, userID, captcha, test, isOtp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyCaptcha?gameID=${gameID}&userID=${userID}&captcha=${captcha}&test=${test}&isOtp=${isOtp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchDeleteAccountVerifyCaptcha: (gameID, userID, captcha, test, isOtp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/deleteAccount?gameID=${gameID}&userID=${userID}&captcha=${captcha}&test=${test}&isOtp=${isOtp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchDeleteAccount: (gameID, userID, otp, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyDeleteAccount?gameID=${gameID}&userID=${userID}&otp=${otp}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchDeactiveAccountVerifyCaptcha: (gameID, userID, captcha, test, isOtp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/deactiveAccount?gameID=${gameID}&userID=${userID}&captcha=${captcha}&test=${test}&isOtp=${isOtp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchDeactiveAccount: (gameID, userID, otp, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyDeactiveAccount?gameID=${gameID}&userID=${userID}&otp=${otp}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },

  fetchCancelDeactiveAccountVerifyCaptcha: (gameID, userID, captcha, test, isOtp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/cancelDeactiveAccount?gameID=${gameID}&userID=${userID}&captcha=${captcha}&test=${test}&isOtp=${isOtp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchCancelDeactiveAccount: (gameID, userID, otp, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyCancelDeactiveAccount?gameID=${gameID}&userID=${userID}&otp=${otp}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },


  fetchCancelDeleteAccountVerifyCaptcha: (gameID, userID, captcha, test, isOtp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/cancelDeleteAccount?gameID=${gameID}&userID=${userID}&captcha=${captcha}&test=${test}&isOtp=${isOtp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchCancelDeleteAccount: (gameID, userID, otp, test) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyCancelDeleteAccount?gameID=${gameID}&userID=${userID}&otp=${otp}&test=${test}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },

  fetchUpdateEmail: (
    gameID,
    userID,
    captcha,
    ppID,
    oldEmail,
    newEmail,
    phone
  ) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/updateEmail?gameID=${gameID}&userID=${userID}&captcha=${captcha}&ppID=${ppID}&oldEmail=${oldEmail}&newEmail=${newEmail}&phone=${phone}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchUpdatePhone: (
    gameID,
    userID,
    captcha,
    currentPhone,
    newPhone,
    ppID,
    email
  ) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/updatePhone?gameID=${gameID}&userID=${userID}&captcha=${captcha}&currentPhone=${currentPhone}&newPhone=${newPhone}&ppID=${ppID}&email=${email}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchVerifyEmailOtp: (gameID, userID, captcha, otp) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyEmailOTP?gameID=${gameID}&userID=${userID}&captcha=${captcha}&otp=${otp}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
  fetchVerifyUpdatePhone: (gameID, userID, otp, captcha) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/user/verifyUpdatePhone?gameID=${gameID}&userID=${userID}&otp=${otp}&captcha=${captcha}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        "Content-Type": "application/json",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );
  },
};

export default game;
