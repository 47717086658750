import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Auth from './Auth';
import Game from './Game';
import Navigate from './Navigate';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  game: Game,
  navigateParams: Navigate
});
