import {
  SET_NAVIGATE_PARAM,
  CLEAR_NAVIGATE_PARAM,
} from "constants/ActionTypes";

const initialState = {
  gameID: "",
  userID: "",
  state: "",
  transferResult: null,
};

const Navigate = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATE_PARAM:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_NAVIGATE_PARAM:
      return initialState;
    default:
      return state;
  }
};

export default Navigate;
