import {
    HIDE_MESSAGE,
    HIDE_CAPTCHA,
    HIDE_RES_VERIFY_PHONE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SIGNUP_SOCIAL_SUCCESS,
    GET_USER_SUCCESS,
    GET_USER_ADVANTAGE_SUCCESS,
    GET_USER_LOGIN_INFO_SUCCESS,
    UPDATE_USER_SUCCESS,
    SHOW_MESSAGE_SUCCESS,
    REGISTER_USER_SUCCESS,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_OTP_SUCCESS,
    VERIFY_PHONE_SUCCESS,
    VERIFY_CAPTCHA_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_PHONE_SUCCESS,
    VERIFY_EMAIL_OTP_SUCCESS,
    VERIFY_UPDATE_PHONE_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: '',
    redirectData: '',
    gameID: '',
    userData: '',
    advantageData: '',
    appIds: [],
    userLoginInfo: {},
    updateResponse: '',
    registerResponse: '',
    logoutId: null,
    typeMessage: '',
    reqVerifyOtp: '',
    reqVerifyPhone: '',
    reqVerifyEmail: '',
    reqVerifyCaptcha: '',
    reqUpdateEmail: '',
    reqUpdatePhone: '',
    reqVerifyEmailOtp: '',
    reqVerifyUpdatePhone: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
                redirectData: action.payload.r
            }
        }
        case SIGNUP_SOCIAL_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                logoutId: action.payload,
                loader: false
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                typeMessage: 'error',
                loader: false
            }
        }
        case SHOW_MESSAGE_SUCCESS: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                typeMessage: 'info',
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                typeMessage: '',
                loader: false
            }
        }
        case HIDE_CAPTCHA: {
            return {
                ...state,
                loader: false,
                reqVerifyCaptcha: ''
            }
        }
        case HIDE_RES_VERIFY_PHONE: {
            return {
                ...state,
                loader: false,
                reqVerifyPhone: '',
                reqVerifyEmail: '',
                reqUpdateEmail: '',
                reqUpdatePhone: '',
                reqVerifyEmailOtp: '',
                reqVerifyUpdatePhone: ''
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                userData: action.payload,
            }
        }
        case GET_USER_LOGIN_INFO_SUCCESS:
            return {
                ...state,
                loader: false,
                userLoginInfo: action.payload
            }
        case GET_USER_ADVANTAGE_SUCCESS: {
            return {
                ...state,
                loader: false,
                advantageData: action.payload,
            }
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                updateResponse: action.payload,
            }
        }
        case REGISTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                registerResponse: action.payload,
            }
        }
        case  VERIFY_EMAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyEmail: action.payload,
            }
        }
        case VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyOtp: action.payload,
            }
        }
        case VERIFY_PHONE_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyPhone: action.payload,
            }
        }
        case VERIFY_CAPTCHA_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyCaptcha: action.payload,
            }
        }
        case UPDATE_EMAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqUpdateEmail: action.payload,
            }
        }
        case UPDATE_PHONE_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqUpdatePhone: action.payload,
            }
        }
        case VERIFY_EMAIL_OTP_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyEmailOtp: action.payload,
            }
        }
        case VERIFY_UPDATE_PHONE_SUCCESS: {
            return {
                ...state,
                loader: false,
                reqVerifyUpdatePhone: action.payload,
            }
        }
        default:
            return state;
    }
}
