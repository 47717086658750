const passport = {
  checkExistAccount(account, callbackFunc) {
    return fetch(`https://id.zing.vn/checkaccount?ACC=${account}`, {
      credentials: "same-origin", // include, *same-origin, omit
    });
  },
  checkPassword(data) {
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`https://id.zing.vn/ajax/checkpwd`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // no-cors, *cors, same-origin
      mode: "cors",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
      body: formBody,
    });
  },
  suggestAccount(inputUser) {
    return fetch(
      `https://id.zing.vn/v2/uname-suggestion?username=${inputUser}`,
      {
        credentials: "same-origin", // include, *same-origin, omit
      }
    );
  },
  getHistoryTransfer(user) {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/transfer/getHistory?userID=${user.userID}&gameID=${user.gameID}&socialID=${user.socialID}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  getListGameTransfer(user) {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/transfer/getList?userID=${user.userID}&gameID=${user.gameID}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  sendOtp({ userID, gameID, captcha, otpType }) {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/transfer/sentOTP?userID=${userID}&gameID=${gameID}&captcha=${captcha}&otpType=${otpType}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  //transferZalo({
  //  userID,
  //  gameID,
  //  socialID,
  //  otpType,
  //  account,
  //  pwd,
  //  re_pwd,
  //  otp,
  //  captcha,
  //}) {
  //  return fetch(
  //    `${process.env.REACT_APP_API_ENDPOINT}/api/transfer/transferZalo`,
  //    {
  //      method: "GET", // *GET, POST, PUT, DELETE, etc.
  //      mode: "cors", // no-cors, *cors, same-origin
  //      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //      credentials: "include", // include, *same-origin, omit
  //      headers: {
  //        "Content-Type": "application/x-www-form-urlencoded",
  //      },
  //      redirect: "follow", // manual, *follow, error
  //      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //      body: new URLSearchParams({
  //        userID,
  //        gameID,
  //        socialID,
  //        otpType,
  //        account,
  //        pwd,
  //        re_pwd,
  //        otp,
  //        captcha,
  //      }),
  //    }
  //  );
  //},
  transferZalo({
    userID,
    gameID,
    socialID,
    otpType,
    account,
    pwd,
    re_pwd,
    otp,
    captcha,
  }) {
    return fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/api/transfer/transferZalo?userID=${userID}&gameID=${gameID}&socialID=${socialID}&otpType=${otpType}&account=${account}&pwd=${encodeURIComponent(
        pwd
      )}&re_pwd=${encodeURIComponent(re_pwd)}&otp=${otp}&captcha=${captcha}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
  historyTransfer({ userID, gameID, socialID }) {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/transfer/getHistory?userID=${userID}&gameID=${gameID}&socialID=${socialID}`,
      {
        credentials: "include", // include, *same-origin, omit
      }
    );
  },
};

export default passport;
