import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import gameService from "services/Game";
import passportService from "services/Passport";
import {
  FETCH_ALL_GAME,
  FETCH_GAME,
  FETCH_LOGIN_GAME,
  FETCH_GAME_TRANSFER,
} from "constants/ActionTypes";
import {
  gameAllSuccess,
  gameInfoSuccess,
  loginGameSuccess,
  getGameFailed,
  setGameAllowTransfer,
} from "actions/Game";
import { showAuthMessage } from "actions/Auth";

const getAllGameRequest = async () =>
  await gameService
    .fetchAll()
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const getGameRequest = async (gameID) =>
  await gameService
    .fetchItem(gameID)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const loginRequest = async (loginTypeName, gameID, mapID) =>
  await gameService
    .fetchLogin(loginTypeName, gameID, mapID)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const getGamesTransfer = async (gameID, userID) =>
  await passportService
    .getListGameTransfer({ userID, gameID })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

function* getAllGame() {
  try {
    const getGame = yield call(getAllGameRequest);
    if (getGame.message) {
      // yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(gameAllSuccess(getGame.data));
    }
  } catch (error) {
    console.log(error);
    // yield put(showAuthMessage(error));
  }
}

function* getGame({ payload }) {
  const { gameID } = payload;
  try {
    const getGame = yield call(getGameRequest, gameID);
    if (getGame.returnCode !== 1) {
      yield put(getGameFailed(getGame));
    } else {
      yield put(gameInfoSuccess(getGame.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* loginGame({ payload }) {
  const { loginTypeName, gameID, mapID } = payload;
  try {
    const getGame = yield call(loginRequest, loginTypeName, gameID, mapID);
    if (getGame.message) {
      // yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(loginGameSuccess(getGame.data));
    }
  } catch (error) {
    console.log(error);
    // yield put(showAuthMessage(error));
  }
}

function* getListGameTransfer({ payload }) {
  const { userID, gameID } = payload;
  try {
    const listGame = yield call(getGamesTransfer, gameID, userID);
    if (listGame.returnCode === 1) {
      yield put(setGameAllowTransfer(listGame.data.game));
    } else {
      //yield put(loginGameSuccess(listGame.data.game));
    }
  } catch (error) {
    console.log(error);
    // yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(FETCH_ALL_GAME, getAllGame);
}

export function* getOneGame() {
  yield takeEvery(FETCH_GAME, getGame);
}

export function* logInGame() {
  yield takeEvery(FETCH_LOGIN_GAME, loginGame);
}
// transfer
export function* getGameTransfer() {
  yield takeEvery(FETCH_GAME_TRANSFER, getListGameTransfer);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(getOneGame),
    fork(logInGame),
    fork(getGameTransfer),
  ]);
}
