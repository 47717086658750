import {
    SET_NAVIGATE_PARAM,
    CLEAR_NAVIGATE_PARAM
} from 'constants/ActionTypes';

const setNavigateParams = (payload) => {
    return {
        type: SET_NAVIGATE_PARAM,
        payload 
    }
};

const clearNavigateParams = () => {
    return {
        type: CLEAR_NAVIGATE_PARAM
    }
};

export {
    setNavigateParams,
    clearNavigateParams,
}