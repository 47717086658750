import {
  FETCH_ALL_GAME,
  FETCH_ALL_GAME_SUCCESS,
  FETCH_GAME,
  FETCH_GAME_SUCCESS,
  FETCH_LOGIN_GAME,
  FETCH_LOGIN_GAME_SUCCESS,
  SHOW_GAME_MESSAGE,
  ON_CLICK_GAME,
  FECTH_GAME_FAILED,
  FETCH_GAME_TRANSFER,
  SET_GAME_TRANSFER,
} from "constants/ActionTypes";

export const gameAll = () => {
  return {
    type: FETCH_ALL_GAME,
  };
};

export const gameAllSuccess = (games) => {
  return {
    type: FETCH_ALL_GAME_SUCCESS,
    payload: games,
  };
};

export const gameInfo = (params) => {
  return {
    type: FETCH_GAME,
    payload: params,
  };
};

export const gameInfoSuccess = (game) => {
  return {
    type: FETCH_GAME_SUCCESS,
    payload: game,
  };
};

export const loginGame = (params) => {
  return {
    type: FETCH_LOGIN_GAME,
    payload: params,
  };
};

export const loginGameSuccess = (game) => {
  return {
    type: FETCH_LOGIN_GAME_SUCCESS,
    payload: game,
  };
};

export const showGameMessage = (message) => {
  return {
    type: SHOW_GAME_MESSAGE,
    payload: message,
  };
};

export const setGameClick = (gameID) => {
  return {
    type: ON_CLICK_GAME,
    payload: gameID,
  };
};

export const getGameFailed = (data) => {
  return {
    type: FECTH_GAME_FAILED,
    payload: data,
  };
};

export const getListAllowTransferZalo = (data) => {
  return {
    type: FETCH_GAME_TRANSFER,
    payload: data,
  };
};
export const setGameAllowTransfer = (data) => {
  return {
    type: SET_GAME_TRANSFER,
    payload: data,
  };
};
